import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';


// pages
const Home = lazy(() => import("./pages/Home"));
const News = lazy(() => import("./pages/News.js"));
const Documents = lazy(() => import("./pages/Documents.js"));
const Leadership = lazy(() => import("./pages/Leadership"));
const PageView = lazy(() => import("./pages/pageView/[page].js"))
const PostView = lazy(() => import("./pages/news/[slug].js"))
const ChartInfo = lazy(() => import("./pages/ChartInfo.js"))
const Contact = lazy(() => import("./pages/Contact.js"))
const Sitemap = lazy(() => import("./pages/SiteMap.js"))
const SearchPage = lazy(() => import("./pages/SearchPage"))
const ContactOnline = lazy(() => import("./pages/ContactOnline.js"))
const Statistics = lazy(() => import("./pages/statistics/[slug].js"))


function App() {
  
  return (
        <Suspense fallback={<div></div>}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/news' element={<News />} />
              <Route path='/category/:slug' element={<News />} />
              <Route path='/document-category/:slug' element={<Documents />} />
              <Route path='/staff/:slug' element={<Leadership />} />
              <Route path='/article/:slug' element={<PostView />} />
              <Route path='/page/:slug' element={<PageView />} />
              <Route path='/page/tekshiruv-va-profilaktika-ishlari' element={<ChartInfo />} />
              <Route path='/page/oavda-chiqishlar' element={<ChartInfo />} />
              <Route path='/link/contact/:slug' element={<Contact />} />
              <Route path='/map' element={<Sitemap />} />
              <Route path='/search' element={<SearchPage />} />
              <Route path='/link/appeall' element={<ContactOnline />} />
              <Route path='/statistics/:slug' element={<Statistics />} />
            </Routes>
        </Suspense> 
  )
}

export default App;
